import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grid,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import api_routes from '../../../../util/api_routes';
import httpStatus from '../../../../util/http_status';
import CircularProgressButton from '../../../common/CircularProgressButton';
import {isEmpty} from "../../../../util/helpers";

function GenerateKey(props) {
    const {pubKey, setPubKey} = props;

    const uuid = require('uuid');
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const optionList = [
        {label: "ECDSA 256", value: "ecdsa256"},
        {label: "ECDSA 384", value: "ecdsa384"},
        {label: "ECDSA 521", value: "ecdsa521"},
        {label: "ED25519", value: "ed25519"},
        {label: "RSA 1024", value: "rsa1024"},
        {label: "RSA 2048", value: "rsa2048"},
        {label: "RSA 3072", value: "rsa3072"},
        {label: "RSA 4096", value: "rsa4096"}
    ];
    const [loadingKey, setLoadingKey] = useState(false);

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(function toggle(prevOpen) {
            return !prevOpen
        });
    };

    const handleClose = (event) => {
        if (!anchorRef.current?.contains(event.target)) {
            setOpen(false);
        }
    };

    function downloadKeyOnAncientBrowser(text, filename) {
        text = text.replaceAll("\n", "\r\n");
        const charCodeArr = new Array(text.length);
        for (let i = 0; i < text.length; ++i) {
            charCodeArr[i] = text.charCodeAt(i);
        }
        const blob = new Blob([new Uint8Array(charCodeArr)], {type: 'text/plain'});
        window.navigator.msSaveOrOpenBlob(blob, filename + '.pem');
    }

    function downloadKeyOnModernBrowser(text, filename) {
        //Handle modern browsers.
        let element = document.createElement('a');
        if (window.btoa) {
            //Base64 Encode to preserve newlines in browsers that support btoa
            element.setAttribute('href', 'data:text;base64,' + window.btoa(text));
        } else {
            //IE9 and lower may be missing required newlines in the key
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + text);
        }
        element.setAttribute('download', filename + '.pem');
        element.style.display = 'none';
        window.document.body.appendChild(element);
        element.click();
        window.document.body.removeChild(element);
    }

    const downloadKey = (filename, text) => {
        if (window.navigator.msSaveOrOpenBlob !== undefined) {
            downloadKeyOnAncientBrowser(text, filename);
        } else {
            downloadKeyOnModernBrowser(text, filename);
        }
    };

    const handleGenerateKey = async (event, option) => {
        setLoadingKey(true);

        try {
            const response = await axios.get(
                `${api_routes.generateKey.endpoint}`, {params: {option: option.value}});
            if (response.status === httpStatus.ok) {
                if (isEmpty(pubKey.name)) {
                    let tempname = `sftpgw-${option.value}-${uuid.v4().substring(0, 8)}`;
                    setPubKey({...pubKey, name: tempname, value: response.data.publicKey, generated: true});
                    downloadKey(tempname, response.data.privateKey);
                } else {
                    setPubKey({...pubKey, value: response.data.publicKey, generated: true});
                    downloadKey(pubKey.name, response.data.privateKey);
                }
            }
        } catch (error) {
            alert("User was not created due to: " + error);
            console.error(error);
        } finally {
            setLoadingKey(false);
        }
    };
    let ariaControls;
    let ariaExpanded;
    if (open) {
        ariaControls = 'split-button-menu';
        ariaExpanded = 'true';
    } else {
        ariaControls = undefined;
        ariaExpanded = undefined;
    }

    return <Grid item container alignItems={"center"} spacing={2}>
        <Grid item>
            <Typography variant='body2'>
                Need a key?
            </Typography>
        </Grid>
        <Grid item>
            <ButtonGroup disableElevation
                         color="primary"
                         variant="outlined"
                         size="small"
                         ref={anchorRef}
                         aria-label="split button"
            >
                <CircularProgressButton label='Generate SSH Key Pair' buttonTextTransform='none' variant='outlined'
                                        size='small' inProgress={loadingKey} mt={0}
                                        onClick={function generateKey(event) {
                                            // noinspection JSIgnoredPromiseFromCall
                                            handleGenerateKey(event, optionList[selectedIndex])
                                        }} style={{borderTopRightRadius: '0px', borderBottomRightRadius: '0px'}}/>
                <Button aria-controls={ariaControls}
                        style={{
                            padding: '2px 9px', textTransform: 'none',
                            borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px'
                        }}
                        aria-expanded={ariaExpanded}
                        aria-label="select merge strategy"
                        aria-haspopup="menu" size='small'
                        onClick={handleToggle} variant='outlined'>
                    {optionList[selectedIndex].label} <ArrowDropDownIcon/>
                </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined}
                    transition disablePortal placement={"bottom-end"}>
                {function conditionalShow({TransitionProps, placement}) {
                    return <Grow
                        {...TransitionProps}
                        style={{
                            // eslint-disable-next-line no-mixed-operators
                            transformOrigin: placement === 'bottom-end' && 'top right'
                                // eslint-disable-next-line no-mixed-operators
                                || 'right bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {optionList.map(function createMenuItem(option, index) {
                                        return <MenuItem
                                            key={option.value}
                                            selected={index === selectedIndex}
                                            onClick={function selectItem(event) {
                                                handleMenuItemClick(event, index)
                                            }}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                }}
            </Popper>
        </Grid>
    </Grid>
}

GenerateKey.prototype = {
    pubKey: PropTypes.object,
    setPubKey: PropTypes.func,
};

export default GenerateKey;
