import React, { useState } from 'react';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { HelpOutline, Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, Tooltip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy as copyIcon } from '@fortawesome/free-regular-svg-icons';
import CustomAlert from './CustomAlert';
import FormControlInput from './FormControlInput';
import PasswordValidator from './PasswordValidator';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import { consolidatePasswordErrorMessage } from '../../util/helpers';

const tooltipLeaveDelay = 100;
const StyledLabelTooltip = styled(Tooltip)`
  margin-left: 5px;

  &:hover {
    cursor: pointer;
  }
`;

function PasswordField(props) {

    const {checkPrincipalHistory} = props;
    const {password, setPassword} = props;
    const {handleChange} = props;
    const {allowCopy, allowToggleVisibility} = props;
    const [fieldType, setFieldType] = useState('password');
    const [showPassword, setShowPassword] = useState(false);
    const [visibilityIcon, setVisibilityIcon] = useState(<VisibilityOff fontSize='small'/>);
    const [transition, setTransition] = useState(false);
    const [color, setColor] = useState('primary');

    const copyPassword = () => {
        navigator.clipboard.writeText(password).then(() => {
            setColor('secondary');
            setTimeout(() => setTransition(true), 2000);
            toast.notify(({onClose}) => <CustomAlert type='success' message='Password copied!' onClose={onClose}/>);
            setTimeout(function revertColor() {
                setColor('primary')
            }, 5000);
            setTimeout(function revertColor() {
                setTransition(false);
            }, 8000);
        });
    }

    const togglePassword = function togglePasswordVisibilityOnUserForm() {
        // If we are switching from showPassword to !showPassword
        if (showPassword) {
            setVisibilityIcon(<VisibilityOff fontSize='small'/>);
            setFieldType('password');
        } else {
            setVisibilityIcon(<Visibility fontSize='small'/>);
            setFieldType('text');
        }
        setShowPassword(!showPassword);
    };

    return (
        <>
            <FormControlInput value={password} width={props.width} required={props?.required} label={props.label}
                              name={props.name ? props.name : 'password'} error={props.error} autoFocus={props.autofocus}
                              autoComplete={props.autoComplete || "password"}
                              errorMessage={consolidatePasswordErrorMessage(props.errorMessage)}
                              {...(props.margin && {margin: props.margin})}
                              {...(props.removeBackground && {removeBackground: props.removeBackground})}
                              {...(props.onKeyDown && {onKeyDown: props.onKeyDown})}
                              onChange={(event) => {
                                  if (props.creatingUser) {
                                      setPassword(event.target.value);
                                      handleChange(event);
                                  } else if (handleChange) {
                                      handleChange(event)
                                  } else {
                                      setPassword(event.target.value);
                                  }
                              }}
                              placeholder={props.placeholder} type={fieldType}
                              endAdornment={<InputAdornment position='end'>
                                  {allowCopy &&
                                      <IconButton variant='body2' color={color} onClick={copyPassword} size='large'>
                                          <FontAwesomeIcon icon={copyIcon} size={'xs'}
                                                           color={color}
                                                           style={
                                                               transition ?
                                                                   {
                                                                       transitionProperty: 'color, border',
                                                                       transitionDuration: '3s',
                                                                       transitionTimingFunction: 'linear',
                                                                       cursor: 'pointer'
                                                                   } : {
                                                                       transitionProperty: 'color, border',
                                                                       transitionTimingFunction: 'linear',
                                                                       cursor: 'pointer'
                                                                   }
                                                           }
                                          />
                                      </IconButton>
                                  }
                                  {allowToggleVisibility &&
                                      <IconButton edge='end' onClick={togglePassword}
                                                  aria-label='toggle password visibility'
                                                  size='small'
                                      >
                                          {visibilityIcon}
                                      </IconButton>
                                  }
                                  {props.tooltip &&
                                      <StyledLabelTooltip
                                          title={
                                              <Typography variant={'body2'}>{props.tooltip} </Typography>}
                                          leaveDelay={tooltipLeaveDelay}>
                                          <HelpOutline fontSize='small'/>
                                      </StyledLabelTooltip>
                                  }
                              </InputAdornment>}
            />
            {props.showPasswordStrengthMeter &&
                <PasswordStrengthMeter password={password}/>
            }
            {props.showPasswordRequirements &&
                <PasswordValidator password={password} usePrincipalHistory={checkPrincipalHistory}/>
            }
        </>
    )
}

PasswordField.propTypes = {
    checkPrincipalHistory: PropTypes.bool,
    password: PropTypes.string,
    setPassword: PropTypes.func,
    handleChange: PropTypes.func,
    width: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    creatingUser: PropTypes.bool,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    allowCopy: PropTypes.bool,
    tooltip: PropTypes.string,
    name: PropTypes.string,
    margin: PropTypes.string,
    removeBackground: PropTypes.bool,
    showPasswordStrengthMeter: PropTypes.bool,
    showPasswordRequirements: PropTypes.bool,
    allowToggleVisibility: PropTypes.bool,
    autoFocus: PropTypes.bool,
    onKeyDown: PropTypes.func,
    autoComplete: PropTypes.string
};
PasswordField.defaultProps = {
    creatingUser: false,
    showPasswordStrengthMeter: false,
    showPasswordRequirements: false,
    allowToggleVisibility: true,
    allowCopy: false,
    autoFocus: false,
};
export default PasswordField;
