import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import makeStyles from '@mui/styles/makeStyles';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useTheme} from '@mui/material/styles';
import {isEmpty} from "../../util/helpers";
import {StyledFormHelperText} from "./FormControlInput";
import FormControl from "@mui/material/FormControl/FormControl";

const useStyles = makeStyles({
    label: {
        fontSize: '0.875rem',
    }
});

const StyledFormControlLabel = styled(FormControlLabel)`
    margin-top: ${props => props.theme.spacing(props.mt)};
    &.Mui-error {
        border: 1px solid ${props => props.theme.palette.error.main ?? "red"};
        padding-right: .5rem;
    }
`;

// noinspection FunctionNamingConventionJS
export default function FormControlCheckbox(props) {
    const theme = useTheme();
    const classes = useStyles();
    const fieldRef = useRef(null);

    React.useEffect(() => {
        if ((props.error || !isEmpty(props.errorMessage)) && fieldRef.current) {
            fieldRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [props.error, props.errorMessage]);

    const { errorMessage, ...checkboxProps } = props;

    return (
        <FormControl error={props.error || !isEmpty(errorMessage)} ref={fieldRef} width={props.width} margin={props.margin}>
            <StyledFormControlLabel
                theme={theme} mt={props.mt}
                error={errorMessage? errorMessage: undefined}
                control={<Checkbox {...checkboxProps} onChange={props.onChange}/>}
                label={props.label}
                classes={{label: classes.label}}
            >

            </StyledFormControlLabel>
            {!isEmpty(errorMessage) &&
                <StyledFormHelperText id={`${props.name}-error-text`}>
                    {errorMessage}
                </StyledFormHelperText>
            }
        </FormControl>
    );
}

FormControlCheckbox.propTypes = {
    mt: PropTypes.number,
    label: PropTypes.string,
    onChange: PropTypes.func,
    errorMessage: PropTypes.string,
};

FormControlCheckbox.defaultProps = {
    mt: 1
};