import React, {useState} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {Grid, Typography} from '@mui/material';
import styled from 'styled-components';
import CircularProgressButton from '../common/CircularProgressButton';
import {convertErrorsToObject} from '../../util/errorHandler';
import httpStatus from '../../util/http_status';
import api_routes from '../../util/api_routes';
import PasswordField from "../common/PasswordField";
import makeStyles from '@mui/styles/makeStyles';

const StyledLogo = styled.img`
  width: 100%;
`;

const StyledFormLogo = styled.div`
  width: 75%;
`;

const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 360px;
`;

const StyledFormContent = styled.div`
    display: flex;
    width: 100%;
    color: rgb(148, 146, 146);
    margin: 24px 0 8px;

    &:before {
        content: "";
        flex: 1 1 0;
        border-bottom: 1px solid rgb(216, 216, 216);
        margin: 0 22px 8px 0;
    }

    &:after {
        content: "";
        flex: 1 1 0;
        border-bottom: 1px solid rgb(216, 216, 216);
        margin: 0 0 8px 22px;
    }
`;

const initChangePasswordForm = {
    newPassword: '',
    confirmNewPassword: '',
}

const useStyles = makeStyles((theme) => ({
    subheader: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        textAlign: "center"
    }
}));

function UpdatePassword(props) {

    const {currentPassword, resetReason} = props;
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [passwordErrors, setPasswordErrors] = useState(initChangePasswordForm);
    const [changePasswordForm, setChangePasswordForm] = useState(initChangePasswordForm);

    const handlePasswordUpdate = async function handlePasswordUpdateOnThePasswordUpdateForm(event) {
        event.preventDefault();
        setIsLoading(true);
        if (changePasswordForm.newPassword === changePasswordForm.confirmNewPassword) {
            try {
                await axios.patch(`${api_routes.adminUser.endpoint}/password`, {
                    currentPassword,
                    newPassword: changePasswordForm.newPassword
                });
                props.onSuccess(props.username, changePasswordForm.newPassword);
            } catch (error) {
                if (error.response.status === httpStatus.badRequest) {
                    let tempErrors = convertErrorsToObject(error.response);
                    setPasswordErrors({confirmNewPassword: '', newPassword: tempErrors.newPassword});
                    setIsLoading(false);
                }
            }
        } else {
            setPasswordErrors({newPassword: '', confirmNewPassword: 'New passwords don\'t match'});
            setIsLoading(false);
        }
    };

    const handleChange = function updateChangePasswordFormFields(event) {
        setPasswordErrors({newPassword: '', confirmNewPassword: ''});
        setChangePasswordForm(
            {...changePasswordForm, [event.target.name]: event.target.value})
    };

    return (
        <Grid container justifyContent='center' alignItems='center' className='login-form'>
            <StyledFormWrapper className='form-wrapper'>
                <StyledFormLogo className='form-logo'>
                    <StyledLogo src='/images/sftpgateway-logo-final.png'
                                alt='SFTPgateway logo'/>
                    <StyledLogo src='/images/sftpgateway-logo-final-text.png'
                                alt='SFTPgateway logo text'/>
                </StyledFormLogo>
                <StyledFormContent className='form-content'>
                    Update Password
                </StyledFormContent>
                <Typography className={classes.subheader} variant='body1'>
                    {resetReason}<br/>Please set a new password to continue.
                </Typography>
                <Grid container item sm={12}>
                <form style={{width: "100%"}} onSubmit={handlePasswordUpdate}>
                    <PasswordField width='100' required name='newPassword'
                                   placeholder='Enter complex password' label='New Password'
                                   autoComplete={"new-password"}
                                   showPasswordRequirements
                                   showPasswordStrengthMeter
                                   checkPrincipalHistory
                                   password={changePasswordForm.newPassword} handleChange={handleChange}
                                   errorMessage={passwordErrors.newPassword}/>
                    <PasswordField errorMessage={passwordErrors.confirmNewPassword}
                                   autoComplete={"new-password"}
                                   width='100' required placeholder='Re-enter complex password'
                                   name='confirmNewPassword' password={changePasswordForm.confirmNewPassword}
                                   label='Confirm New Password' handleChange={handleChange}/>
                    <CircularProgressButton label='Update Password' variant='contained' mt={2}
                                            type='submit' inProgress={isLoading}/>
                </form>
                </Grid>
            </StyledFormWrapper>
        </Grid>
    );
}

UpdatePassword.propTypes = {
    currentPassword: PropTypes.string.isRequired,
    username: PropTypes.string,
    onSuccess: PropTypes.func,
    resetReason: PropTypes.string
};

UpdatePassword.defaultProps = {};

export default UpdatePassword;
