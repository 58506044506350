import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {DialogActions, DialogContent, DialogTitle, Grid, Box} from '@mui/material';
import UploadFile from './modalComponents/UploadFile';
import GenerateKey from './modalComponents/GenerateKey';
import FormControlInput from '../../common/FormControlInput';
import FormControlCheckbox from '../../common/FormControlCheckbox';
import CircularProgressButton from '../../common/CircularProgressButton';
import {isEmpty, isEmptyObject} from '../../../util/helpers';
import {convertErrorsToObject} from '../../../util/errorHandler';

function SshKeyModal(props) {
    const [errors, setErrors] = useState({});
    const {addPublicKey, handleCloseModal} = props;
    const [publicKey, setPublicKey] = useState({
        name: '',
        enabled: true,
        value: '',
        generated: false,
    });

    const handleTextChange = function handleTextChangeOnSSHModalForm(event) {
        event.preventDefault();
        setPublicKey({...publicKey, [event.target.name]: event.target.value, generated: false});
    };

    const handleCheckboxChange = function handleCheckboxChangeOnSSHModalForm(event) {
        setPublicKey({...publicKey, [event.target.name]: event.target.checked});
    };

    const validateKey = function validatePublicKey(key) {
        let tempErrors = {};
        if (isEmpty(key.name)) {
            tempErrors['name'] = 'Name required.';
        }
        if (isEmpty(key.value)) {
            tempErrors['value'] = 'Value required.';
        }
        if (!isEmptyObject(tempErrors)) {
            throw tempErrors;
        }
    }

    const handleSaveKey = async function addPublicKeyToSSHTable(event) {
        event.preventDefault();
        try {
            validateKey(publicKey);
            await addPublicKey(publicKey);
            setErrors({});
            handleCloseModal();
        } catch (errors) {
            if (!isEmpty(errors.response)) {
                let tempErrors = convertErrorsToObject(errors.response);
                setErrors({...tempErrors});
            } else {
                setErrors(errors);
            }
        }
    };

    return <Box sx={{p: 2}}>
        <DialogTitle>Add SSH Key</DialogTitle>
        <DialogContent>
            <FormControlInput value={publicKey.name} width='100' label='Key Name' name='name'
                              errorMessage={errors.name} placeholder='Enter unique key name'
                              onChange={handleTextChange} required/>
            <FormControlCheckbox name='enabled' checked={publicKey.enabled}
                                 onChange={handleCheckboxChange} size='small' label='Enabled'/>
            <FormControlInput value={publicKey.value} width='100' label='SSH Public Key' name='value' required
                              multiline rows={8} onChange={handleTextChange} errorMessage={errors.value}
                              placeholder='Paste SSH public key here...'
                              helperText='Copy and paste your SSH public key here. Or do one of the following:'
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <UploadFile pubKey={publicKey} setPubKey={setPublicKey}/>
                </Grid>
                <Grid item xs={12}>
                    <GenerateKey pubKey={publicKey} setPubKey={setPublicKey}/>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <CircularProgressButton label='Add' buttonTextTransform='none' size='small' onClick={handleSaveKey}/>
            <CircularProgressButton label='Cancel' buttonTextTransform='none' onClick={handleCloseModal}
                                    size='small' variant='text'/>
        </DialogActions>
    </Box>
}

SshKeyModal.prototype = {
    addPublicKey: PropTypes.func,
    handleCloseModal: PropTypes.func,
};
export default SshKeyModal;
