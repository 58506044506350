import qs from 'querystring';
import axios from 'axios';
import api_routes from '../../util/api_routes';

const {clientid, clientsecret} = window._env_ || {};
const CLIENT_ID = clientid || process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = clientsecret || process.env.REACT_APP_CLIENT_SECRET;

export const KEY_USER_LOGIN_METHOD = 'user-login-method';
export const VALUE_USER_LOGIN_METHOD_SSO = 'sso';
export const VALUE_USER_LOGIN_METHOD_SLINK = 'slink';

export const authenticationService = {
    login,
    loginWithSingleUseToken,
    logout,
    clearTokenInfo,
    setToken,
    setName,
    getToken: () => localStorage.getItem('sftpgw'),
    getUsername: () => localStorage.getItem('sftpgw-user'),
};

function getAxiosInstanceWithoutInterceptors() {
    let instance = axios.create();
    // Clear the response interceptor that loops 401 back to logout
    instance.interceptors.response.handlers = [];
    return instance;
}

async function hitLoginEndpoint(data) {
    let instance = getAxiosInstanceWithoutInterceptors();
    let response = await instance.request({
        url: `${api_routes.auth.endpoint}`,
        method: 'post',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        auth: {
            username: CLIENT_ID,
            password: CLIENT_SECRET,
        },
        data: data
    });

    const { access_token: token, username, resetPassword, resetReason, roles } = response.data;

    if (!resetPassword) {
        await setToken(token);
        await setName(username);
    }

    await setBearerAuth(token);

    return { token, username, resetPassword, resetReason, roles: roles?.map(object => object.authority) };
}

async function login(username, password) {
    let data = qs.stringify({
        'grant_type': 'password',
        'username': username,
        'password': password
    });

    return await hitLoginEndpoint(data);
}

async function loginWithSingleUseToken(singleUseToken){

    let data = qs.stringify({
        'grant_type': 'urn:ietf:params:oauth:grant-type:single-use-auth',
        'code': singleUseToken
    });


    return await hitLoginEndpoint(data);
}

async function setBearerAuth(token){
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

async function setToken(token){
    localStorage.setItem('sftpgw', token);
}
async function setName(name){
    localStorage.setItem('sftpgw-user', name);
}


async function logout() {
    let target = window.location.origin;
    console.log("Logging out..")

    let response;
    try {
        response = await axios.request({
            method: 'POST',
            url: api_routes.logout.endpoint,
            params: {
                target
            }
        });
    } catch (e) {
        console.error(e);
    } finally {
        localStorage.removeItem('sftpgw');
        localStorage.removeItem('sftpgw-user');
        localStorage.removeItem(KEY_USER_LOGIN_METHOD);
        delete axios.defaults.headers.common['Authorization'];
    }

    if (response?.data?.idpRedirect) {
        target = response.data.idpRedirect;
    }
    if(target.startsWith(window.location.origin)) {
        window.history.replaceState({}, null, target);
        window.location.reload();
    } else {
        window.location.href = target;
    }
}

function clearTokenInfo() {
    localStorage.removeItem('sftpgw');
    localStorage.removeItem('sftpgw-user');
}
